import React from 'react';
import {graphql} from "gatsby"
import Search from "../components/tronpedia/search";
import Topics from "../components/tronpedia/topics";
import Featured from "../components/tronpedia/featured";
import EmailCapture from "../components/EmailCapture";
import {Helmet} from "react-helmet";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const Article = ({data, location}) => {
    const article = data.allWpTronpediaArticle.edges[0].node

    return (
        <>
            {article.tronpedia_article_info.image !== null &&
                <Helmet>
                    <meta property="og:image" content={article.tronpedia_article_info.image.sourceUrl}
                          data-react-helmet="true"/>
                    <meta name="twitter:image:src" content={article.tronpedia_article_info.image.sourceUrl}
                          data-react-helmet="true"/>
                </Helmet>
            }
            <div className="tronpedia mx-auto" style={{maxWidth: "1920px"}}>
                <div className="row no-gutters mt-5 p-lg-5 p-2">
                    <div className="col-12">
                        <Search location={location}/>
                    </div>
                </div>

                <div className="row no-gutters my-5 p-lg-5 p-2">
                    <h3 className="">POPULAR TOPICS</h3>
                    <div className="col-12">
                        <Topics/>
                    </div>
                </div>
                <div className="row no-gutters tronpedia-article">
                    <div className="col-md-4 col-12 order-md-0 order-1 tronpedia-article-bar px-md-5 px-2 mb-md-5 mb-0">
                        <a href="/tronpedia/" id="tronpedia-article-back-btn" className="d-none d-md-block">
                            <div className="tronpedia-article-bar-back">&lt;</div>
                        </a>
                        <h2>Related Articles</h2>
                        {data.relatedArticles.edges.length > 0 && data.relatedArticles.edges.map((node, index) => {
                            let article = node.node;
                            return (
                                <a href={"/tronpedia/" + article.slug} id={"tronpedia-related-article" + index}
                                   key={"tronpedia-related-article" + index}>
                                    <p className="my-5">{article.title}</p>
                                </a>
                            )
                        })
                        }
                    </div>
                    <div className="col-md-8 col-12 order-md-1 order-0 px-md-5 px-2 mb-md-5 mb-0">
                        <h1 className="tronpedia-article-title">{article.title}</h1>
                        {article.tronpedia_article_info.image !== null &&
                            <img src={article.tronpedia_article_info.image.sourceUrl} alt={article.title}
                                 className="img-fluid my-3"/>
                        }
                        <SimpleReactLightbox>
                            <SRLWrapper>
                                <div className="tronpedia-article-text"
                                     dangerouslySetInnerHTML={{__html: article.tronpedia_article_info.article}}/>
                            </SRLWrapper>
                        </SimpleReactLightbox>
                    </div>
                </div>
                <Featured/>


                <EmailCapture/>

            </div>

        </>
    )
}


export default Article

export const pageQuery = graphql`
query($slug: String!) {
  allWpTronpediaArticle(filter: { slug: { eq: $slug } }) {
    edges {
         node {
            slug
        title
        tronpedia_article_info {
          article
          category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
  }
  relatedArticles: allWpTronpediaArticle(
  filter: { slug: { ne: $slug } }
  limit: 4) {
    edges {
         node {
            slug
        title
        tronpedia_article_info {
          article
          category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
  }
}
`
